import { useEffect, useRef } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const Container = styled.div`
	position: absolute;
	left: 1rem;
	height: 1.25rem;
	overflow: hidden;
	pointer-events: none;
	display: flex;
	margin-top: 0.0625rem;

	@media (max-width: 768px) {
		color: ${colors.GREY_DS.G3};
		margin-top: 0.1875rem;
	}
`;

const ScrollingContainer = styled.div`
	transform: translateY(0);
	transition: transform 0.6s ease-in-out;
	display: flex;
	flex-direction: column;
`;

const StyledItem = styled.span<{ $typeLabel: string }>`
	color: ${colors.GREY_DS.G3};
	margin-bottom: 2px;
	transition: opacity 0.4s ease-in-out;
	opacity: 0;
	margin-right: 0.25rem;

	&:first-child {
		opacity: 1;
	}

	&.placeholder-list-item {
		${({ $typeLabel }) => getFontDetailsByLabel($typeLabel)};
	}

	@media (max-width: 768px) {
		margin-right: 0.1875rem;
	}
`;

type SearchPlaceholderProps = {
	itemFontLabel: string;
};

const getTransformOffset = (itemFontLabel: string) => {
	switch (itemFontLabel) {
		case TYPE_LABELS.UI_LABEL_MEDIUM:
			return 20;
		case TYPE_LABELS.UI_LABEL_REGULAR:
			return 16;
		default:
			return 20;
	}
};

const SearchPlaceholder = ({ itemFontLabel }: SearchPlaceholderProps) => {
	const SEARCH_PLACEHOLDER_ITEMS = [
		strings.SEARCHBAR_TEXTS.EXPERIENCES_AND_CITIES,
		strings.SEARCHBAR_TEXTS.BURJ_KHALIFA,
		strings.SEARCHBAR_TEXTS.DUBAI,
		strings.SEARCHBAR_TEXTS.VATICAN_MUSEUMS,
		strings.SEARCHBAR_TEXTS.ROME,
		strings.SEARCHBAR_TEXTS.EIFFEl_TOWER,
		strings.SEARCHBAR_TEXTS.PARIS,
		strings.SEARCHBAR_TEXTS.EXPERIENCES_AND_CITIES,
	];

	const scrollingContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const list = scrollingContainerRef.current;
		if (!list) return;

		const transformOffset = getTransformOffset(itemFontLabel);
		let animationInterval: NodeJS.Timer | undefined,
			index = 0,
			lastTime = 0;

		const animateListItems = (time: number) => {
			const currentItem: HTMLSpanElement = list.children[
				index
			] as HTMLSpanElement;
			const nextItem: HTMLSpanElement = list.children[
				index + 1
			] as HTMLSpanElement;

			// animation runs after every 2s till the last list item
			if (!lastTime || time - lastTime >= 2000) {
				currentItem.style.opacity = '0';
				setTimeout(() => (nextItem.style.opacity = '1'), 250);

				const listStyles = window.getComputedStyle(list);
				const matrix = listStyles.transform;
				const currentTop = matrix.split(',')[5]; // translateY is the 6th value in the transform matrix

				list.style.transform = `translateY(${
					parseInt(currentTop) - transformOffset - 2
				}px)`;

				lastTime = time;
				index++;
			}

			animationInterval = setInterval(() => {
				if (index !== SEARCH_PLACEHOLDER_ITEMS.length - 1) {
					requestAnimationFrame(animateListItems);
				} else {
					clearInterval(animationInterval);
				}
			}, 2000);
		};

		requestAnimationFrame(animateListItems);

		() => {
			clearInterval(animationInterval);
		};
	}, []);

	return (
		<Container>
			<StyledItem
				className={'placeholder-list-item'}
				$typeLabel={itemFontLabel}
				role={'presentation'}
				aria-hidden={'true'}
			>
				{strings.SEARCHBAR_TEXTS.SEARCH_FOR}{' '}
			</StyledItem>
			<ScrollingContainer
				ref={scrollingContainerRef}
				role={'presentation'}
				aria-hidden={'true'}
			>
				{SEARCH_PLACEHOLDER_ITEMS.map((item, index) => (
					<StyledItem
						className={'placeholder-list-item'}
						$typeLabel={itemFontLabel}
						key={index}
					>
						{item}
					</StyledItem>
				))}
			</ScrollingContainer>
		</Container>
	);
};

export default SearchPlaceholder;
