import { useEffect, useRef, useState } from 'react';

import { trackSearchSuggestionsViewed } from 'Utils/analytics';
import fetchWrapper from 'Utils/fetchWrapper';
import { debounce } from 'Utils/gen';
import { getSuggestionsLoadTime } from 'Utils/searchUtils';

import { SEARCH_DEBOUNCE_DELAY, SEARCH_LOCATION } from 'Constants/constants';

const useNativeSearch = (searchQuery: string, language = 'en') => {
	const prevSearchQuery = useRef(searchQuery);

	const [startTime, setStartTime] = useState(0);
	const [shouldTrack, setShouldTrack] = useState(false);

	const [isFetchingResults, setIsFetchingResults] = useState(false);
	const [topCitySearchCodeResults, setTopCitySearchCodeResults] = useState<
		Array<Object>
	>([]);
	const [topProductSearchCodeResults, setTopProductSearchCodeResults] =
		useState<Array<Object>>([]);
	const [
		topCollectionsSearchCodeResults,
		setTopCollectionsSearchCodeResults,
	] = useState<Array<Object>>([]);

	const fetchSearchResults = () => {
		setStartTime(Date.now());
		setShouldTrack(true);

		const url = `${
			process.env.NEXT_PUBLIC_API_CDN_BASE_URL_SEARCH
		}/api/v3/search/?query=${encodeURIComponent(
			searchQuery,
		)}&language=${language.toLowerCase()}`;

		fetchWrapper(url)
			.then(res => res.json())
			.then(res => {
				setIsFetchingResults(false);
				const { results } = res;
				if (results.length) {
					const cityResults = results.find(
						({ type }: { type: string }) => type === 'CITY',
					);
					if (cityResults)
						setTopCitySearchCodeResults(cityResults.values);

					const collectionResults = results.find(
						({ type }: { type: string }) => type === 'COLLECTION',
					);
					if (collectionResults)
						setTopCollectionsSearchCodeResults(
							collectionResults.values,
						);

					const productResults = results.find(
						({ type }: { type: string }) => type === 'PRODUCT',
					);
					if (productResults)
						setTopProductSearchCodeResults(productResults.values);
				}
			})
			.catch(() => {
				setIsFetchingResults(false);
			});
	};

	useEffect(() => {
		const debouncedFetchSearchResults = debounce(
			fetchSearchResults,
			SEARCH_DEBOUNCE_DELAY,
		);

		if (searchQuery.length > 0) {
			setIsFetchingResults(true);

			// If query was empty before, fetch immediately
			!prevSearchQuery.current
				? fetchSearchResults()
				: debouncedFetchSearchResults();
		} else {
			setTopCitySearchCodeResults([]);
			setTopProductSearchCodeResults([]);
			setTopCollectionsSearchCodeResults([]);
		}

		prevSearchQuery.current = searchQuery;
		return () => {
			debouncedFetchSearchResults.cancel();
		};
	}, [searchQuery]);

	useEffect(() => {
		if (!isFetchingResults && searchQuery && shouldTrack) {
			trackSearchSuggestionsViewed({
				searchLocation: SEARCH_LOCATION.TOP,
				query: searchQuery,
				loadTime: getSuggestionsLoadTime(startTime),
				cityResultsLength: topCitySearchCodeResults.length,
				productResultsLength: topProductSearchCodeResults.length,
				collectionResultsLength: topCollectionsSearchCodeResults.length,
			});
			setShouldTrack(false);
		}
	}, [
		isFetchingResults,
		searchQuery,
		shouldTrack,
		startTime,
		topCitySearchCodeResults.length,
		topCollectionsSearchCodeResults.length,
		topProductSearchCodeResults.length,
	]);

	return {
		isFetchingResults,
		topCitySearchCodeResults,
		topProductSearchCodeResults,
		topCollectionsSearchCodeResults,
	};
};

export default useNativeSearch;
