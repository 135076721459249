import { useCallback, useEffect, useState } from 'react';

import { sendCoralogixLog } from 'Utils/coralogix/log';
import fetchWrapper from 'Utils/fetchWrapper';

import { LOG_CATEGORIES, LOG_LEVELS } from 'Constants/constants';

type Props = {
	language?: string;
	shouldFetchClosebyCities: boolean;
};

type SelectorState = {
	closeByCities: any[];
};

const useFetchCloseByCities = ({
	shouldFetchClosebyCities,
	language = 'en',
}: Props) => {
	const [hasFetchedResults, setHasFetchedResults] = useState(false);
	const [closeByCities, setCloseByCities] = useState([]);

	const fetchCloseByCities = useCallback(async () => {
		try {
			const url = `${
				process.env.NEXT_PUBLIC_API_CDN_BASE_URL_SEARCH
			}/api/v3/search/nearby-cities/?limit=5&language=${language.toLowerCase()}`;

			fetchWrapper(url)
				.then(res => res.json())
				.then(res => {
					setHasFetchedResults(true);
					setCloseByCities(res.cities);
				})
				.catch(() => {
					setHasFetchedResults(true);
				});
		} catch (error) {
			sendCoralogixLog({
				title: 'Error fetching closeby cities',
				severity: LOG_LEVELS.ERROR as any,
				methodName: 'fetchCloseByCities',
				metaData: error,
				category: LOG_CATEGORIES.API_ERROR,
			});
		}
	}, []);

	useEffect(() => {
		if (hasFetchedResults || !shouldFetchClosebyCities) return;
		fetchCloseByCities();
	}, [fetchCloseByCities, hasFetchedResults, shouldFetchClosebyCities]);

	return {
		closeByCities,
	} as SelectorState;
};

export default useFetchCloseByCities;
